























import { isMobile } from '@/@core/helpers/useBreakpoint';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'KButtonAction',
  setup() {
    //

    return {
      isMobile
    };
  }
});
